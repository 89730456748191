/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrdit účast"}>
        <SiteHeader />

        <Column className="pb--40 pt--80" name={"informacje"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 pt--50" content={"Potwierdź udział"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--20" name={"potwierdź-udział"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style3 ff--3 fs--22 w--300 ls--002 lh--12" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Kontakt e-mail","type":"email","required":true,"placeholder":"Wpisz swój e-mail"},{"name":"Numer telefonu","type":"text","placeholder":"Telefon +480"},{"name":"Wyślij","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-62lsa9 --center bg--top pb--80 pt--60" name={"stopka"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"<span style='color: white'>Czekamy na Ciebie!</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style='color: white'><a href=\"https://saywebpage.com\">Tworzenie stron</a></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}